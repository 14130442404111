import React from 'react';
import './source.less';

const Source = ({ source }) => {
  if (!source) {
    return;
  }

  return (
    <section id="source" class="no-print">
      <span class="source-label">źródło:</span>
      <a href={source} target="_blank" rel="noopener noreferrer">
        {source}
      </a>
    </section>
  );
};

export default Source;
