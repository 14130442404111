import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import BackgroundImage from 'gatsby-background-image';
import Menu from '../components/menu';
import Taglist from '../components/taglist';
import Spacer from '../components/spacer';
import Source from '../components/source';
import SEO from '../components/seo';
import Serves from '../components/serves';
import { get } from 'lodash'

export default ({ data }) => {
  const recipe = data.prismicRecipe;
  const { tags } = recipe;
  const { name, serves, intro_excerpt, intro_rest, cover_image, part_ingredients, part_steps, tips, final, source, part_preparations, thumbnail, publication_date } = recipe.data;
  const image = cover_image && cover_image.fluid;
  const metaImageSrc = thumbnail && thumbnail.fluid && thumbnail.fluid.src || cover_image && cover_image.fluid && cover_image.fluid.src;
  const showPreparations = get(part_preparations, '0.part_preparations_list.text', '').length > 0;

  const ldJson = {
    '@type': 'Recipe',
    name,
    image: [metaImageSrc],
    author: {
      '@type': 'Person',
      'name': 'Kamil Wysocki'
    },
    datePublished: publication_date,
    description: intro_excerpt.text,
    keywords: tags.join(', '),
    recipeYield: serves,
  };

  const renderIngredients = (ingredientsList) => {
    if (!ingredientsList) { return null; }

    return ingredientsList.map((ingredientPart, id) => {
      const { part_name: partName, part_ingredients_list: partIngredients } = ingredientPart;

      return (<div key={id}>
        {partName && <h3>{partName}</h3>}
        <div className="ingredients-list" dangerouslySetInnerHTML={{ __html: partIngredients.html }} />
      </div>);
    });
  };

  const renderSteps = (stepsList) => {
    if (!stepsList) { return null; }

    return stepsList.map((ingredientPart, id) => {
      const { part_name: partName, part_steps_list: partSteps } = ingredientPart;

      return (<div key={id}>
        {partName && <h3>{partName}</h3>}
        <div dangerouslySetInnerHTML={{ __html: partSteps.html }} />
      </div>);
    });
  }

  const renderPreparations = (preparationsList) => {
    if (!showPreparations) { return null; }

    return preparationsList.map((preparationPart, id) => {
      const { part_name: partName, part_preparations_list: partSteps } = preparationPart;

      return (<div key={id}>
        {partName && <h3>{partName}</h3>}
        <div dangerouslySetInnerHTML={{ __html: partSteps.html }} />
      </div>);
    });
  }

  const renderTips = (tips) => {
    return (tips && <div dangerouslySetInnerHTML={{ __html: tips.html }} />);
  }

  const renderFinal = (final) => {
    return (final && <div dangerouslySetInnerHTML={{ __html: final.html }} />);
  }


  return (
    <Layout>
      <SEO title={`${name} - przepis`} description={intro_excerpt.text} imageSrc={metaImageSrc} ldJson={ldJson} />
      <Menu />
      <BackgroundImage fluid={image} className="recipe-background">
        <div className="recipe-background-overlay"></div>
      </BackgroundImage>
      <div className="single-recipe">
        <h1>{name}</h1>
        <Spacer noPrint />
        <div dangerouslySetInnerHTML={{ __html: intro_excerpt.html }} />
        <div dangerouslySetInnerHTML={{ __html: intro_rest.html }} />
        <h2>Składniki</h2>
        {renderIngredients(part_ingredients)}
        {showPreparations && <h2>Przygotowania</h2>}
        {renderPreparations(part_preparations)}
        <h2>Przepis</h2>
        {renderSteps(part_steps)}
        {!!tips.html.length && <h2>Porady</h2>}
        {renderTips(tips)}
        {renderFinal(final)}
        <Spacer noPrint />
        {source && <Source source={source} />}
        {serves && <Serves serves={serves} />}
        <Taglist tags={tags} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SingleRecipe($id: String!) {
    prismicRecipe(id: {eq: $id } ) {
      id
      data {
        name
        slug
        serves
        publication_date
        cover_image {
          alt
          copyright
          url
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        thumbnail {
          alt
          copyright
          url
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        final {
          html
        }
        intro_excerpt {
          html
          raw
          text
        }
        intro_rest {
          html
        }
        part_preparations {
          part_name
          part_preparations_list {
            html
            text
          }
        }
        part_ingredients {
          part_name
          part_ingredients_list {
            html
          }
        }
        part_steps {
          part_name
          part_steps_list {
            html
          }
        }
        tips {
          html
        }
      }
      tags
    }
  }
`;
