import React from 'react';
import { RiRestaurantLine } from 'react-icons/ri'
import './serves.less';

const Serves = ({ serves }) => {
  if (!serves) {
    return;
  }

  return (
    <section id="serves">
      <span className="serves-label"><RiRestaurantLine size={16} /></span>
      <span className="serves-value">{serves}</span>
    </section>
  );
};

export default Serves;
